import "./App.css";
import {
  Card,
  Flex,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import ResponsiveAppBar from "./ResponsiveAppBar";
import CreateDrawer from "./CreateDrawer";
import "@aws-amplify/ui-react/styles.css";
import { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listWishes } from "./graphql/queries";
import CreateWishForm from "./CreateWishForm";

function App({ signOut, user }) {
  const [wishes, setWishes] = useState([]);

  useEffect(() => {
    const fetchWishes = async () => {
      try {
        const wishData = await API.graphql(
          graphqlOperation(listWishes, {
            filter: {
              owner: { eq: `${user.attributes.sub}::${user.username}` },
            },
          })
        );
        const wishes = wishData.data.listWishes.items;
        setWishes(wishes);
      } catch (error) {
        console.log(error);
      }
    };
    fetchWishes();
  }, [user]);

  const wishesSetter = (wish) => {
    setWishes([...wishes, wish]);
  };

  return (
    <View>
      <ResponsiveAppBar signOut={signOut} user={user} />
      <Card>
        <Flex direction="column">
          {/* wish list */}
          {wishes.length ? (
            <Table highlightOnHover={true}>
              <TableHead>
                <TableRow>
                  <TableCell as="th">Wish</TableCell>
                  <TableCell as="th">Description</TableCell>
                  <TableCell as="th">Link</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {wishes.map((wish, index) => (
                  <TableRow key={index}>
                    <TableCell>{wish.name}</TableCell>
                    <TableCell>{wish.description}</TableCell>
                    <TableCell>{wish.link}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            'Press "+" button to add a wish!'
          )}
          <CreateDrawer>
            <CreateWishForm wishesSetter={wishesSetter} />
          </CreateDrawer>
        </Flex>
      </Card>
    </View>
  );
}

export default withAuthenticator(App);
