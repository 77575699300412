import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

export default function CreateDrawer(props) {
  const [state, setState] = React.useState({
    open: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ open: open });
  };

  return (
    <div>
      <Fab
        onClick={toggleDrawer(true)}
        color="primary"
        aria-label="add"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        <AddIcon />
      </Fab>
      <Drawer
        anchor="bottom"
        open={state["open"]}
        onClose={toggleDrawer(false)}
      >
        <Box sx={{ width: "auto" }} role="presentation">
          {React.cloneElement(props.children, { onClick: toggleDrawer })}
        </Box>
      </Drawer>
    </div>
  );
}
