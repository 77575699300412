import { Button, Card, Flex, TextField } from "@aws-amplify/ui-react";
import { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { createWish } from "./graphql/mutations";

const initialCreateFormState = { name: "", description: "", link: "" };

export default function CreateWishForm(props) {
  const [createFormState, setcreateFormState] = useState(
    initialCreateFormState
  );
  const [hasError, setHasError] = useState(false);

  const setInput = (key, value) => {
    if (hasError && key === "name") setHasError(false);
    setcreateFormState({ ...createFormState, [key]: value });
  };

  const addWish = async (event) => {
    try {
      if (!createFormState.name) {
        setHasError(true);
        return;
      }
      const wish = { ...createFormState };
      await API.graphql(graphqlOperation(createWish, { input: wish })).then(
        () => {
          props.onClick && props.onClick(false)(event);
          setcreateFormState(initialCreateFormState);
          props.wishesSetter && props.wishesSetter(wish);
        }
      );
    } catch (err) {
      console.log("error creating todo:", err);
    }
  };

  return (
    <Card>
      <Flex direction="column">
        <TextField
          onChange={(event) => setInput("name", event.target.value)}
          value={createFormState.name}
          labelHidden
          placeholder="Wish"
          hasError={hasError}
          errorMessage="Required"
        />
        <TextField
          onChange={(event) => setInput("description", event.target.value)}
          value={createFormState.description}
          labelHidden
          placeholder="Description"
          errorMessage="There is an error"
        />
        <TextField
          onChange={(event) => setInput("link", event.target.value)}
          value={createFormState.link}
          labelHidden
          placeholder="Link"
          errorMessage="There is an error"
        />
        <Button
          onClick={(event) => addWish(event)}
          variation="primary"
          width="100%"
        >
          Add
        </Button>
      </Flex>
    </Card>
  );
}
