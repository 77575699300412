/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:5cfb1667-ab4d-4915-8a92-8ede7613e1fc",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_uaCQ6ptpC",
    "aws_user_pools_web_client_id": "5pmthl7gvg80eporr7pv6aaor4",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "gifts-20220911165055-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://dt4w14hyyy79b.cloudfront.net",
    "aws_appsync_graphqlEndpoint": "https://qi3bga76fbawlh3vmku7i7mkd4.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-hqoxnm2udzgw7aht6vz3wuieqq"
};


export default awsmobile;
